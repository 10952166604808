import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Placeholder from '@/Placeholder.vue';

const params = new URLSearchParams(window.location.search);
const affiliate = params.get('affiliate');
affiliate && localStorage.setItem('affiliate', affiliate);

createApp(App).use(router).mount('#app');
//createApp(Placeholder).use(router).mount('#app');
